<template>
  <!-- 转换功能组件 -->
  <div class="convert-card">
    <h2 v-if="title" class="title">{{ title }}</h2>
    <div class="convert-list">
      <template v-for="(item, idx) in displayedList">
        <div class="covert-item" :key="idx" :style="{ background: item.theme[0], borderColor: item.theme[1] }" @click="setPoint(item.name)">
          <img class="feature-content-img" :src="$getFile(item.img)" alt="">
          <span>{{ item.title }}</span>
        </div>
      </template>
      <div v-if="isExpandable && !this.expanded" class="covert-item covert-item-expand" :to="{name: 'feature'}">
        <img class="feature-content-img" :src="$getFile('tools.webp')" alt="" />
        <span>{{content.name}}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Convert',
  props: {
    title: {
      type: String,
      required: false,
      default: () => ''
    },
    content: {
      type: Object,
      required: true,
      default: () => { }
    },
  },
  data() {
    return {
      list: [],
      path: '',
      expanded: false
    }
  },
  computed: {
    displayedList() {
      return this.expanded ? this.list : this.list.slice(0, 11)
    },
    isExpandable() {
      return this.list.length > 11
    }
  },
  created() {
    this.list = Object.values(this.content).reduce((p, n) => p.concat(n.list), []).sort((a, b) => {
      return a.sort - b.sort
    })

  },
  mounted() {
    this.path = this.$route.path
  },
  methods: {
    setPoint(name) {
      const list = name.toLocaleLowerCase().split('-')
      let event_name = ''
      console.log('this.$route.path', list)
      let source = ''
      if (this.path === '/') {
        source = 'homepage'
      }
      console.log(source)
      if (list[0] === 'all') {
        event_name = 'All_convert'
      } else if (list[0] === 'pdf') {
        event_name = 'To_' + list[2]
      } else {
        event_name = 'From_' + list[0]
      }
      this.$api.saveOperationLog({
        event_name,
        event_key: event_name,
        source
      })
    },
    toggleExpand() {
      this.expanded = !this.expanded
    }
  }
}
</script>

<style scoped lang="scss">
@media (min-width: 980px) {
  .convert-card {
    width: 1180px;
    box-sizing: border-box;
    margin: 0 auto;
    padding: 120px 0;
    .title {
      font-size: 36px;
      font-family: Poppins-Bold, Poppins;
      font-weight: bold;
      color: var(--theme-font-normal);
      line-height: 52px;
      text-align: center;
      margin-bottom: 60px;
    }
    .convert-list {
      display: grid;
      grid-template-columns: repeat(6, 1fr);
      gap: 20px;

      .covert-item {
        text-align: center;
        padding: 25px 0;
        border-radius: 8px;
        border: 1px solid transparent;
        &.covert-item-expand {
          border: 1px solid rgba(5, 2, 30, 0.2);
          background-color: white;
          cursor: pointer;
        }
        &:hover {
          img {
            transform: scale(1.25);
          }
        }
        img {
          width: 48px;
          height: 48px;
          transition: all 200ms;
        }
        span {
          display: block;
          font-size: 14px;
          font-family: Poppins-Medium, Poppins;
          font-weight: 500;
          color: var(--theme-font-normal);
          line-height: 22px;
          margin-top: 12px;
        }
      }
    }
  }
}

@media (max-width: 980px) {
  .convert-card {
    width: 6.86rem;
    box-sizing: border-box;
    margin: 0 auto;
    padding: 1.4rem 0;
    .title {
      font-size: 0.48rem;
      font-family: Poppins-Bold, Poppins;
      color: var(--theme-font-normal);
      line-height: 0.72rem;
      text-align: center;
      margin-bottom: 0.6rem;
    }
    .convert-list {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 0.16rem;

      .covert-item {
        text-align: center;
        padding: 0.32rem 0.2rem;
        border-radius: 0.16rem;
        border: 0.02rem solid transparent;

        &:hover {
          img {
            transform: scale(1.25);
          }
        }
        img {
          width: 0.68rem;
          height: 0.68rem;
          transition: all 200ms;
        }
        span {
          display: block;
          font-size: 0.24rem;
          font-family: Poppins-Medium, Poppins;
          font-weight: 500;
          color: var(--theme-font-normal);
          line-height: 0.27rem;
          margin-top: 0.18rem;
        }
        &.covert-item-expand {
          border: 1px solid rgba(5, 2, 30, 0.2);
          background-color: white;
          cursor: pointer;
        }
      }
    }
  }
}
</style>