<template>
  <!-- 首页banner 组件 -->
  <div class="banner-container">
    <SwiBanner :list="content.list">
      <template v-slot:default="{ data }">
        <div class="banner-box">
          <div class="banner-left">
            <h1>{{ data.title }}</h1>
            <p>{{ data.des }}</p>
            <div v-show="data.btnTips" class="btn-desc mt20">{{ data.btnTips }}</div>
          </div>
          <div class="banner-right">
            <img :src="$getFile(data.img)" alt="">
          </div>
        </div>
      </template>
    </SwiBanner>
  </div>
</template>

<script>
import SwiBanner from '@/components/ui/SwiBanner.vue'
export default {
  name: 'SwiperBanner',
  components: { SwiBanner },
  props: {
    content: {
      type: Object,
      required: true,
      default: () => { }
    },
  },
  data() {
    return {
    }
  },
  computed: {
    topEleBg() {
      return "#FFF"
    }
  },
  methods: {
    swiperSwitch(idx) {
      this.$refs.swiper.swiper.slideTo(idx + 1)
    }
  }
}
</script>

<style scoped lang="scss">
@media (min-width: 981px) {
  .banner-container {
    overflow: hidden;
    .banner-box {
      display: flex;
      justify-content: center;
      align-items: center;
      padding-top: 22px;
      padding-bottom: 24px;
      padding-left: 20px;
      padding-right: 20px;
      background: var(--theme-home-primary);
      max-width: 1580px;
      border-radius: 16px;
      margin-top: 32px;
      .banner-left {
        width: 627px;
        height: auto;
        h1 {
          font-size: 56px;
          font-family: Poppins;
          color: var(--theme-font-normal);
          line-height: 72px;
          margin-bottom: 16px;
          margin-top: -10px;
        }
        p {
          font-size: 20px;
          font-family: Poppins-Regular, Poppins;
          font-weight: 400;
          color: var(--theme-font-normal);
          line-height: 30px;
        }
        button {
          padding: 17px 78px;
          font-size: 18px;
          font-family: Poppins-Medium, Poppins;
          font-weight: 500;
          color: #ffffff;
          line-height: 29px;
          border-radius: 32px;
          cursor: pointer;
          background: var(--theme-primary);
          &:hover {
            background: var(--theme-focus);
          }
        }
        .btn-desc {
          font-size: 18px;
          line-height: 27px;
          font-family: Poppins-Regular, Poppins;
          text-align: left;
        }
      }
      .banner-right {
        width: 486px;
        height: 378px;
        margin-left: 67px;
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }

    .temp {
      margin: 32px auto 0;
    }
  }
}
@media (max-width: 980px) {
  .banner-container {
    .banner-box {
      padding: 0rem 0 0.8rem;
      height: 10.1rem;
      display: flex;
      flex-direction: column-reverse;
      justify-content: flex-end;
      background: var(--theme-home-primary);

      .banner-left {
        width: 6.86rem;
        margin: 0 auto;
        text-align: center;
        h1 {
          font-size: 0.56rem;
          padding: 0 0.2rem;
          font-family: Poppins;
          color: var(--theme-font-normal);
          line-height: 0.72rem;
          margin-bottom: 0.2rem;
          margin-top: 0.3rem;
        }
        p {
          font-size: 0.32rem;
          font-family: Poppins-Regular, Poppins;
          font-weight: 400;
          color: var(--theme-font-normal);
          line-height: 0.48rem;
          padding: 0 0.2rem;
        }
        button {
          padding: 0.17rem 0.78rem;
          font-size: 0.18rem;
          font-family: Poppins-Medium, Poppins;
          font-weight: 500;
          color: #ffffff;
          line-height: 0.29rem;
          display: none;
          border-radius: 0.32rem;
          cursor: pointer;
          background: var(--theme-primary);
          &:hover {
            background: var(--theme-focus);
          }
        }
        .btn-desc {
          font-size: 0.18rem;
          line-height: 0.27rem;
          font-family: Poppins-Regular, Poppins;
          text-align: left;
        }
      }
      .banner-right {
        width: 6.46rem;
        height: 5.03rem;
        margin: 0 auto;
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }

    .temp {
      margin: .54rem 0.32rem 0;
      background: linear-gradient(135deg, #32b4ff 0%, #d642ff 100%);
      border-radius: 0.24rem;
    }
  }
}
</style>