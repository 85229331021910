<template>
  <!-- troubles组件 -->
  <div class="trouble">
    <h2 class="title">{{ content.title }}</h2>
    <p class="des">{{ content.des }}</p>
    <div class="trouble-list">
        <div :key="idx" class="trouble-item" v-for="(item, idx) in content.list">
          <img :src="$getFile(item.img, 'tools_images')" alt="">
          <div>
            <p class="mini-title">{{ item.title }}</p>
            <span class="des">{{ item.des }}</span>
          </div>
        </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Troubles',
  props: {
    content: {
      type: Object,
      required: true,
      default: () => { }
    },
  },
  data() {
    return {}
  }
}
</script>

<style scoped lang="scss">
@media (min-width: 980px) {
    .trouble {
        width: 1180px;
        padding: 148px 0 100px;
        margin: 0 auto;
        text-align: center;

        .title {
            font-size: 36px;
            font-family: Poppins-Bold, Poppins;
            font-weight: bold;
            color: #060222;
            line-height: 56px;
        }

        .des {
          width: 1000px;
          font-size: 14px;
          font-family: Poppins-Regular, Poppins;
          color: #000029;
          line-height: 24px;
          margin: 0 auto;
        }

        .trouble-list {
            margin-top: 48px;
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            gap: 20px;

            .trouble-item {
              border-radius: 8px;
              border: 1px solid #EEEEEE;
              padding: 0 20px 30px;
              transition: all .3s;

              &:hover {
                transform: translateY(-5px);
                -webkit-transform: translateY(-5px);
                -moz-transform: translateY(-5px);
                -ms-transform: translateY(-5px);
                -o-transform: translateY(-5px);
              }

                img {
                    width: 100px;
                    height: 100px;
                    margin-top: 30px;
                }

                p {
                    font-size: 18px;
                    font-family: Poppins-Medium, Poppins;
                    font-weight: 500;
                    color: #060222;
                    line-height: 22px;
                    margin-top: 20px;
                    margin-bottom: 4px;
                }

                span {
                    font-size: 14px;
                    font-family: Poppins-Regular, Poppins;
                    font-weight: 400;
                    color: #80848E;
                    line-height: 22px;
                }
            }
        }

        .download-btn {
          margin: 40px auto 0;
        }
    }
}

@media (max-width: 980px) {
    .trouble {
        width: 6.86rem;
        padding: 1.4rem 0;
        margin: 0 auto;
        text-align: center;

        .title {
            text-align: center;
            width: 6.86rem;
            font-size: 0.48rem;
            font-family: Poppins;
            color: #000029;
            line-height: 0.64rem;
            margin-bottom: .16rem;
        }

        .des {
          width: 6.86rem;
          font-size: 0.28rem;
          font-family: Poppins-Regular, Poppins;
          color: #000000;
          line-height: 0.36rem;
        }

        .trouble-list {
            margin-top: 0.48rem;
            display: grid;
            grid-template-columns: repeat(1, 1fr);
            gap: 0.24rem;
            text-align: left;

            .trouble-item {
                display: flex;
                align-items: flex-start;
                background: #FFFFFF;
                border-radius: 0.16rem;
                border: 0.02rem solid #EEEEEE;
                padding: .3rem;

                img {
                    width: 1.18rem;
                    height: 1.18rem;
                    margin-right: 0.24rem;
                }

                p {
                    font-size: 0.32rem;
                    font-family: Poppins-Medium, Poppins;
                    font-weight: 500;
                    color: #060222;
                    line-height: 0.4rem;
                    margin-bottom: 0.2rem;
                }

                span {
                    font-size: 0.28rem;
                    font-family: Poppins-Regular, Poppins;
                    font-weight: 400;
                    color: #83808E;
                    line-height: 0.36rem;
                }
            }
        }

        .download-btn {
          margin: .48rem auto 0;
        }
    }
}</style>