<template>
  <div>
    <div
      v-for="(component, name) in components"
      :key="name"
      class="layout-items"
    >
      <component :is="component.component" v-bind="component.props"></component>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      components: {},
    };
  },
  methods: {
  },
  async created() {
    try {
      // 动态导入和解析components.json文件
      const response = await import("../siteJson/index.json");
      const componentConfig = await this.$replacePlaceholders(response.default);
      console.log(componentConfig, response);
      // 遍历配置，动态引入和注册组件，并传递props
      for (const [name, config] of Object.entries(componentConfig)) {
        console.log(config.path,">>>")
        this.$set(this.components, name, { component:name, props: config.props });
      }
    } catch (error) {
      console.error("Failed to load component configuration:", error);
    }
  },
};
</script>
<style lang="scss">

.layout-items{
  margin-bottom: 16px;
  &:last-child{
    margin-bottom: 0;
  }
}
</style>
